<template>
  <div>
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>
        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              placeholder="Search here..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <my-button
        type="orange"
        @click="addQuestion"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT SOAL BARU
      </my-button>
    </div>
    <br />
    <br />

    <label>Filter Jenjang</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="tryout_level_id"
        :options="levelOptionList"
        @change="changeLevel"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4">Filter Mata Pelajaran</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="tryout_subject_id"
        :options="subjectOptionList"
        @change="changeSubject"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-danger"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="minus-circle" class="my-icon" />&nbsp;Delete
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      editData: false,
      tryout_level_id: null,
      levelOptionList: [],
      tryout_subject_id: null,
      subjectOptionList: [],
      isBusy: false,
      items: [],
      fields: [
        {
          key: "no",
          label: "No"
        },
        {
          key: "title",
          label: "Judul",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "level",
          label: "Level",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_subject",
          label: "Mapel",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "tryout_level",
          label: "Jenjang",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "actions",
          label: "Action"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      addModal: false,
      inputData: {
        name: "",
        description: "",
        tryout_level_id: null,
        tryout_subject_id: null,
        id: null
      },
      showLoading: false,
      levelOption: [],
      subjectOption: []
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.totalRows = this.items.length;
    this.levelList();
    this.subjectList();
    if(this.$route.query.level) {
      this.level_id = this.$route.query.level;
    } 
    if(this.$route.query.tryout_subject) {
      this.tryout_subject_id = this.$route.query.tryout_subject;
    } 
    this.questionList();
  },

  watch: {
    $route: function(newVal) {
      this.tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      this.tryout_subject_id = this.$route.query.tryout_subject ? this.$route.query.tryout_subject : null;
      this.subjectList();
      this.questionList();
    }
  },

  methods: {
    ...mapActions({
      getTryoutLevelList: types.GET_TRYOUT_LEVEL_LIST,
      getTryoutQuestionList: types.GET_TRYOUT_QUESTION_LIST,
      getTryoutSubjectList: types.GET_TRYOUT_SUBJECT_LIST,
      deleteTryoutQuestion: types.DELETE_TRYOUT_QUESTION
    }),
    addQuestion() {
      this.$router.push("/tryout-question/add");
    },
    changeSubject() {
      if(this.tryout_subject_id) {
        this.$router.push("/tryout-question/list?tryout_level="+this.tryout_level_id+"&tryout_subject="+this.tryout_subject_id);
      } else {
        this.$router.push("/tryout-question/list?tryout_level="+this.tryout_level_id);
      }
    },
    changeLevel() {
      if(this.tryout_level_id) {
        this.$router.push("/tryout-question/list?tryout_level="+this.tryout_level_id);
      } else {
        this.$router.push("/tryout-question/list");
      }
    },
    levelList() {
      this.getTryoutLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [];
          this.levelOptionList = [{value: null, text: "All"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
            this.levelOptionList.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    subjectList() {
      var tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      if(tryout_level_id) {
        this.getTryoutSubjectList({params: {tryout_level: tryout_level_id}})
          .then(response => {
            var res = response;
            this.subjectOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.subjectOptionList.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.subjectOptionList = [{value: null, text: "All"}];
      }
    },
    questionList() {
      this.tryout_level_id = this.$route.query.tryout_level ? this.$route.query.tryout_level : null;
      this.tryout_subject_id = this.$route.query.tryout_subject ? this.$route.query.tryout_subject : null;
      this.isBusy = true;
      this.getTryoutQuestionList({params: {tryout_level: this.tryout_level_id, tryout_subject: this.tryout_subject_id}})
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              title: res[i].title,
              tryout_level: res[i].tryout_level,
              tryout_level_id: res[i].tryout_level_id,
              tryout_subject: res[i].tryout_subject,
              tryout_subject_id: res[i].tryout_subject_id,
              level: res[i].level
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEdit(no) {
      this.$router.push("/tryout-question/edit/"+no);
    },
    handleDelete(index) {
      this.$bvModal.msgBoxConfirm('Delete this data?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value) {
            this.deleteTryoutQuestion({id:index.id})
              .then(response => {
                this.questionList();
                this.showMessageBox("Deleted", "Success", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
          this.temp = value;
        })
        .catch(err => {
          this.temp = err;
        });
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>